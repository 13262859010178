import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Container from './ui/container';

export default function PageHeader({
    title,
    image,
    description,
}: {
    title: string;
    description?: string;
    image: IGatsbyImageData;
}) {
    return (
        <div className="relative pt-12 lg:pt-28 lg:pb-12 overflow-hidden">
            <div className="absolute h-full w-full top-0 left-0">
                <GatsbyImage
                    alt=""
                    image={image}
                    className="h-full lg:h-auto lg:w-full"
                    imgClassName="image"
                />
                <div className="w-full bg-white bg-opacity-60 h-full top-0 left-0 z-10 absolute glassy"></div>
            </div>
            <Container>
                <div className="page-header relative z-10 p-3 lg:p-0">
                    <h1 className="font-semibold text-3xl lg:text-4xl text-gray-900 text-center">
                        {title}
                    </h1>
                    {description && (
                        <p className="mt-6 text-lg text-gray-900">
                            {description}
                        </p>
                    )}
                </div>
            </Container>
        </div>
    );
}

import Layout from 'components/layout';
import PageHeader from 'components/pageheader';
import Pagination from 'components/pagination';
import { ProductsContainer } from 'components/product';
import Container from 'components/ui/container';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useIntl } from 'react-intl';

export default function BlogListTemplate({
    data,
    pageContext,
}: {
    data: GatsbyTypes.ProductsListingQuery;
    pageContext: {
        limit: number;
        skip: number;
        numPages: number;
        currentPage: number;
    };
}) {
    const products = data.products.nodes.map((item) => ({
        id: item.id,
        image: (item.frontmatter?.image?.childImageSharp
            ?.gatsbyImageData as unknown) as IGatsbyImageData,

        slug: item.fields?.slug as string,
        title: item.frontmatter?.title as string,
        category: item.frontmatter?.category as string,
    }));

    const intl = useIntl();

    return (
        <Layout
            seo={{
                title: intl.formatMessage({
                    id: 'productPageTitle',
                }),
            }}
        >
            <PageHeader
                image={
                    (data.banner?.frontmatter?.productsListingBanner
                        ?.childImageSharp
                        ?.gatsbyImageData as unknown) as IGatsbyImageData
                }
                title={intl.formatMessage({
                    id: 'productPageTitle',
                })}
            />
            <div className="py-16 products">
                <Container>
                    <ProductsContainer products={products} />
                </Container>
                <div className="my-8">
                    <Pagination
                        currentPage={pageContext.currentPage}
                        totalPages={pageContext.numPages}
                        type="products"
                    />
                </div>
            </div>
        </Layout>
    );
}

export const query = graphql`
    query ProductsListing($locale: String!, $limit: Int!, $skip: Int!) {
        products: allMdx(
            filter: {
                fields: {
                    sourceName: { eq: "products" }
                    locale: { eq: $locale }
                }
            }
            skip: $skip
            limit: $limit
        ) {
            nodes {
                id
                fields {
                    slug
                }
                frontmatter {
                    title
                    category
                    image {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        banner: mdx(fields: { sourceName: { eq: "options" } }) {
            frontmatter {
                productsListingBanner {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;

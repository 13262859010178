import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Link from 'utils/link';
import Container from './ui/container';

interface ProductProps {
    id: string;
    title: string;
    category: string;
    slug: string;
    image: IGatsbyImageData;
}

export function ProductItem({ title, category, slug, image }: ProductProps) {
    return (
        <div className="max-w-xs mx-auto my-4 overflow-hidden bg-gray-600 rounded-lg shadow-2xl dark:bg-gray-800 hover:-translate-y-2 transform transition-transform duration-300">
            <Link to={slug} title={title} className="block">
                <GatsbyImage image={image} alt={title} className="object-cover h-full" />
            </Link>
            <div className="w-56 mx-auto -mt-5 overflow-hidden bg-gray-400 rounded-lg shadow-lg md:w-40 relative">
                <h3 className="py-1 tracking-wide text-xs text-center text-gray-50">{category}</h3>
            </div>
            <div className="p-2 text-center">
                <Link to={slug} title={title} className="block">
                    <h3 className="text-lg lg:text-lg  text-gray-50">
                        {title} 
                    </h3>
                </Link>
            </div>
    </div>
        
    );
}

export function ProductsContainer({ products }: { products: ProductProps[] }) {
    const productsList = products.map((product) => (
        <ProductItem
            id={product.id}
            image={product.image}
            slug={product.slug}
            title={product.title}
            category={product.category}
            key={product.id}
        />
    ));
    return (
        <Container>
            <div className="flex flex-wrap">{productsList}</div>
        </Container>
    );
}
